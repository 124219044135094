import React from "react"

import * as styles from "./impressum.module.scss"

import AppBase from "../util/app-base"
import Header from "../components/header"
import Footer from "../components/footer"
import SiteEnclosingWrapper from "../components/site-enclosing-wrapper"
import { InternalRainbowLink } from "../components/rainbow-link"

export default function AnmeldePage() {
  return (
    <AppBase title="FLAGincluded - Impressum">
      <SiteEnclosingWrapper>
        <Header
          title="Impressum"
          button={
            <InternalRainbowLink
              title="Spenden"
              href="/spenden"
              fillWidth={false}
            />
          }
        />
        <div className={styles.content}>
          <h2
            className={styles.heading}
            style={{ marginBottom: "10px", marginTop: "20px" }}
          >
            Impressum
          </h2>
          <p>
            Informationspflicht laut §5 E-Commerce Gesetz, §14
            Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht
            laut §25 Mediengesetz.
          </p>
          <br />
          <p>
            <strong> FLAGincluded</strong>
          </p>
          <p> Homosexuelle Initiative Wien</p>
          <p> Heumühlgasse 14/1</p>
          <p> 1040 Wien</p>
          <p> ZVR-Nr.: 524 534 408</p> <p>UID: ATU64602914</p>
          <br />
          <h3>Haftung für Inhalte dieser Website</h3>
          <p>
            Wir entwickeln die Inhalte dieser Webseite ständig weiter und
            bemühen uns korrekte und aktuelle Informationen bereitzustellen.
            Leider können wir keine Haftung für die Korrektheit aller Inhalte
            auf dieser Webseite übernehmen, speziell für jene die seitens
            Dritter bereitgestellt wird. Sollten Ihnen problematische oder
            rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu
            <a href="mailto:kontakt@flagincluded.at"> kontakieren</a>.
          </p>
          <br />
          <h3>Haftung für Links auf dieser Website</h3>
          <p>
            Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt
            wir nicht verantwortlich sind. Wenn Ihnen rechtswidrige Links auf
            unserer Webseite auffallen, bitte wir Sie uns zu
            <a href="mailto:kontakt@flagincluded.at"> kontakieren</a>.
          </p>
          <br />
          <h3>Urheberrechtshinweis</h3>
          <p>
            Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos)
            unterliegen dem Urheberrecht. Falls notwendig, werden wir die
            unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich
            verfolgen. Sollten Sie auf dieser Webseite Inhalte finden, die das
            Urheberrecht verletzen, bitten wir Sie uns zu
            <a href="mailto:kontakt@flagincluded.at"> kontakieren</a>.
          </p>
          <br />
        </div>
      </SiteEnclosingWrapper>
      <Footer />
    </AppBase>
  )
}
